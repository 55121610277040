
<template>
  <div>
    <!-- Page Content -->
    <router-view />
  </div>
</template>

<script>

export default ({

})

</script>

<style>

</style>